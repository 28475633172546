@import "variables";

/* Block newsletter */
#columns #newsletter_block_left .form-group {
  margin-bottom: 0; }
  #columns #newsletter_block_left .form-group .form-control {
    max-width: 222px;
    display: inline-block;
    margin-right: 6px; }
    @media (min-width: 768px) and (max-width: 1199px) {
      #columns #newsletter_block_left .form-group .form-control {
        margin-bottom: 10px;
        margin-right: 0; } }
#columns #newsletter_block_left .success_inline, #columns #newsletter_block_left .warning_inline {
  text-align: left;
  padding: 1px 0 0 0;
  margin-bottom: -19px; }
#columns #newsletter_block_left .success_inline {
  color: #418B19; }
#columns #newsletter_block_left .warning_inline {
  color: #f13340; }

/* Block newsletter footer */
@media(min-width:768px){
	#footer #newsletter_block_left {
	  overflow: hidden;
	  margin-bottom: 30px; }
}
  @media (max-width: 767px) {
    #footer #newsletter_block_left {
      width: 100%; } }
  #footer #newsletter_block_left h4 {
    background: none;
    float: left;
    padding: 7px 16px 5px 0;
    text-transform: none;
    font-size: 21px;
    line-height: 25px;
    border: none; }
    #footer #newsletter_block_left h4:after {
      display: none; }
  #footer #newsletter_block_left .block_content {
    overflow: hidden; }
  #footer #newsletter_block_left .form-group {
    margin-bottom: 0; }
    #footer #newsletter_block_left .form-group .form-control {
      height: 40px;
      max-width: 267px;
      background: theme-color(white);
      border:none;
      color: #777;
      display: inline-block;
      float: left; }
      #footer #newsletter_block_left .form-group .form-control:focus {
        -moz-box-shadow: black 0px 0px 0px;
        -webkit-box-shadow: black 0px 0px 0px;
        box-shadow: black 0px 0px 0px; }
    #footer #newsletter_block_left .form-group .button-small {
      margin-left: -43px;
      border: none;
      background: none;
      text-align: center;
      color: #908f8f;
      padding: 8px; }
      #footer #newsletter_block_left .form-group .button-small:before {
		content: "\f0e0";
		color: #b2b2b2;
		font-family: "FontAwesome";
		font-size: 18px;
		line-height: 22px;
	  }
      #footer #newsletter_block_left .form-group .button-small:hover {
        color: theme-color(white) !important; }
      #footer #newsletter_block_left .form-group .button-small span {
        display: none; }
  #footer #newsletter_block_left .warning_inline {
    display: block;
    color: #f13340;
    font-size: 13px;
    line-height: 26px;
    clear: both; }
    @media (min-width: 1200px) {
      #footer #newsletter_block_left .warning_inline {
        display: inline-block;
        position: relative;
        top: -35px;
        margin-bottom: -35px;
        left: 15px;
        clear: none; } }
  #footer #newsletter_block_left .newsletter-input {
    max-width: 300px !important; }
